/**
 * External dependencies
 */
import React from "react"

/**
 * Internal dependencies
 */
import Layout from "../components/layout"
import Seo from "../components/seo"
import GetURL from "../components/common/site/get-url"
import { useStaticQuery, graphql } from "gatsby"
import { toCapitalize } from "@components/common/utils";
import { Col, Row, Container} from "react-bootstrap"
import {
  OFFICE_PAGE_URL
} from "../components/common/site/constants"
import { Link } from "gatsby";
const TeamDetail = () => {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      offices(sort:"Sort:ASC", publicationState: LIVE, where: {Publish: true}) {
        Office_Name
        URL
      }
      menuBurgers(publicationState: LIVE, sort: "Sort:asc") {
        Publish
        Sort
        Menu_Parent {
          Parent_Label
          Parent_Url {
            Alias
          }
          Sub_Burger_Menu {
            Label
            Link
            Url {
              Alias
            }
          }
        }
      }
    }
  }
`)
  return (
    <Layout>
      <Seo title="Sitemap" />
      <div className="property-info-wrapper terms-blk sitemap">
      <Container className="property-info">
      <Row>
          <h1>Sitemap</h1>
        </Row>
    <Row>
      <Col className="col-count">
      {data?.glstrapi?.menuBurgers?.map((item, i) => {
        return (
          <ul><li>                  {item.Menu_Parent?.Parent_Url?.Alias ? <GetURL label={item.Menu_Parent?.Parent_Label} alias={item.Menu_Parent?.Parent_Url?.Alias} /> : item.Menu_Parent?.Parent_Label}
                                <ul>
              {item?.Menu_Parent?.Sub_Burger_Menu?.map((list, j) => {
                return (
                  <li className="header-link-small">
                    <GetURL link={list?.Link} customLink={list.Link} class="is-active" label={list.Label} alias={list.Url?.Alias} />
                    {/* <Link activeClassName="is-active" to="/">
                        Buy with us
                      </Link> */}
                      {list.Label === "Our Branches" &&
                      <ul>
                      {data?.glstrapi?.offices?.map((item, i) => {
                                  return(
                                    <li>
                                    <Link to={`${OFFICE_PAGE_URL?.alias}/${item?.URL}`}>
                                      {toCapitalize(item?.Office_Name)}
                                    </Link>
                                    </li>
                                  )
                                })}
                      </ul>}
                  </li>)
              })}
            </ul>
            </li>
          </ul>
        )
      })}
      </Col>
    </Row>
      </Container>
      </div>
    </Layout>
  )
}

export default TeamDetail
